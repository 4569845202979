@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;900&family=Rhodium+Libre&family=Roboto&display=swap');

html, body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	overscroll-behavior: none;
	font-family: Montserrat;
	::-webkit-scrollbar {
		display: none;
	}
	scroll-behavior: smooth;
}



